import { rest } from 'msw'

const apiUrl = process.env.REACT_APP_API_URL

const handlers = [
    rest.post(`${apiUrl}`, async (_req, res, ctx) => {
        return res(ctx.text('OK'))
    }),
]

export { handlers }
