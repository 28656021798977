import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'react-bootstrap'

type Props = {
    redirectUrl: string
}
const LoginButton = ({ redirectUrl }: Props): JSX.Element => {
    const { loginWithRedirect } = useAuth0()

    return (
        <Button
            onClick={() =>
                loginWithRedirect({
                    redirectUri: redirectUrl,
                })
            }
        >
            Login/Register
        </Button>
    )
}

export default LoginButton
