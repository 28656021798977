import * as React from 'react'
import { Container, Jumbotron, Image } from 'react-bootstrap'
import CopyToClipboard from 'react-copy-to-clipboard'
import { UserProfile } from 'types/user'

interface Props {
    userProfile: UserProfile
}
const DashboardHeader = ({ userProfile: user }: Props): JSX.Element => {
    return (
        <Jumbotron fluid>
            <Container>
                <Image width={64} height={64} className="mr-3" src={user.avatar} alt={user.name} rounded />
                <h1>Hallo {user.name}</h1>
                <p>
                    Deine persönliche Newsletter-Mailadresse lautet{' '}
                    <CopyToClipboard text={`${user.id}@feedelta.com`}>
                        <strong>
                            <u>{user.id}@feedelta.com </u> 📋
                        </strong>
                    </CopyToClipboard>
                </p>
            </Container>
        </Jumbotron>
    )
}

export default DashboardHeader
