import { StrictMode, FC } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import { ErrorBoundary } from 'react-error-boundary'
import { ToastContainer } from 'react-toastify'
import { Auth0Provider } from '@auth0/auth0-react'
import { BrowserRouter } from 'react-router-dom'
import ErrorFallback from 'common/atoms/ErrorFallback'
import configService from 'services/Config/configService'
import store from 'store'
import reportWebVitals from './reportWebVitals'
import App from './App'
import './index.scss'
import { StoreProvider } from 'easy-peasy'

Sentry.init({
    dsn: 'https://276da6e0be8148619115f08a5d963d4e@o173361.ingest.sentry.io/5731160',
    integrations: [new Integrations.BrowserTracing()],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
})

const render = async (Component: FC): Promise<void> => {
    if (process.env.NODE_ENV === 'development') {
        // eslint-disable-next-line
        const { worker } = require('./common/tests/server/browser')
        await worker.start()
    }

    const basePath = process.env.REACT_APP_SUBFOLDER ? process.env.REACT_APP_SUBFOLDER : '/'

    try {
        configService.validateConfig()
    } catch (error) {
        Sentry.captureMessage(`Config safe guard: ${error.message}`, Sentry.Severity.Critical)
        ReactDOM.render(
            <ErrorFallback resetErrorBoundary={() => []} error={error}></ErrorFallback>,
            document.getElementById('root'),
        )
        return
    }

    ReactDOM.render(
        <StrictMode>
            <Sentry.ErrorBoundary fallback={ErrorBoundary}>
                <Auth0Provider
                    domain={configService.getAuth0BaseUrl()}
                    clientId={configService.getAuth0ClientId()}
                    redirectUri={configService.getAuth0RedirectUrl()}
                    cacheLocation="localstorage"
                    useRefreshTokens={true}
                    audience={`${configService.getBackendHostname()}/api`}
                >
                    <BrowserRouter basename={basePath}>
                        <StoreProvider store={store}>
                            <Component />
                        </StoreProvider>
                    </BrowserRouter>
                    <ToastContainer
                        position="top-right"
                        autoClose={3000}
                        hideProgressBar={true}
                        newestOnTop={true}
                        closeOnClick
                        rtl={false}
                        limit={5}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </Auth0Provider>
            </Sentry.ErrorBoundary>
        </StrictMode>,
        document.getElementById('root'),
    )
}

render(App)

if (module.hot) {
    module.hot.accept('./App', () => {
        // eslint-disable-next-line @typescript-eslint/no-var-requires
        const NextApp = require('./App').default
        render(NextApp)
    })
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
