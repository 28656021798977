import * as React from 'react'
import { Container, Jumbotron } from 'react-bootstrap'
import NavigationBar from 'components/NavigationBar'
import { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Landingpage = (): JSX.Element => {
    const { isAuthenticated } = useAuth0()

    useEffect(() => {
        if (isAuthenticated) {
            window.location.href = `${window.location.href}dashboard`
        }
    }, [isAuthenticated])

    return (
        <>
            <NavigationBar isLoggedIn={false} />

            <Jumbotron fluid>
                <Container>
                    <h1 data-testid="headline">Feedelta ist super!</h1>
                </Container>
            </Jumbotron>
        </>
    )
}

export default Landingpage
