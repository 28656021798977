import { useAuth0 } from '@auth0/auth0-react'
import { isForbidden } from 'common'
import { useEffect, useState } from 'react'
import { Redirect, Route, RouteProps } from 'react-router'
import configService from 'services/Config/configService'
import { useTypedStoreActions, useTypedStoreState } from 'store/hooks'
import { userAccountStatus, UserRole } from 'types/user'
import * as Sentry from '@sentry/react'

export type ProtectedRouteProps = {
    roles: UserRole[]
} & RouteProps

export default function ProtectedRoute({ roles, ...routeProps }: ProtectedRouteProps): JSX.Element {
    // accessToken
    const setAccessToken = useTypedStoreActions((state) => state.user.setAccessToken)

    // userAccount
    const userAccount = useTypedStoreState((state) => state.user.userAccount)
    const setUserAccount = useTypedStoreActions((state) => state.user.setUserAccount)
    const getUserAccount = useTypedStoreActions((state) => state.user.getUserAccount)

    // userProfile
    const setUserProfile = useTypedStoreActions((state) => state.user.setUserProfile)

    const [userAccountStatus, setUserAccountStatus] = useState<userAccountStatus>('loading')
    const { user, isAuthenticated, getAccessTokenSilently } = useAuth0()

    const backendHostname = configService.getBackendHostname()
    const loginPath = configService.getLoginPath()
    const verificationPath = configService.getVerificationPath()

    useEffect(() => {
        const checkAuthentication = async (): Promise<void> => {
            setUserAccountStatus('loading')

            if (!isAuthenticated) {
                try {
                    const auth0AccessToken = await getAccessTokenSilently({ audience: `${backendHostname}/api` })
                    setAccessToken(auth0AccessToken)
                    await getUserAccount()
                } catch (err) {
                    if (isForbidden(err)) {
                        setUserAccountStatus('inVerification')
                    } else {
                        setUserAccount(null)
                        setUserAccountStatus('failed')
                        Sentry.captureException(err, { level: Sentry.Severity.Fatal, extra: { user } })
                    }
                }
            }
        }
        checkAuthentication()
    }, [])

    useEffect(() => {
        if (!userAccount?.role) {
            setUserAccountStatus('notAuthorized')
        } else if (roles.includes(userAccount?.role)) {
            setUserProfile({
                id: userAccount.id,
                name: user.name,
                avatar: user.picture,
                role: userAccount.role,
            })

            setUserAccountStatus('succeeded')
        } else {
            setUserAccountStatus('notAuthorized')
        }
    }, [userAccount])

    if (userAccountStatus === 'succeeded') {
        return <Route {...routeProps} />
    }

    if (userAccountStatus === 'inVerification') {
        return <Redirect to={{ pathname: verificationPath }} />
    }

    if (userAccountStatus === 'failed') {
        return <Redirect to={{ pathname: loginPath, search: '?error=login' }} />
    }

    return <>LOADING</>
}
