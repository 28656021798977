import LoginButton from 'atoms/LoginButton'
import LogoutButton from 'atoms/LogoutButton'
import { Nav, Navbar } from 'react-bootstrap'
import configService from 'services/Config/configService'

interface Props {
    isLoggedIn: boolean
}

const NavigationBar = ({ isLoggedIn }: Props): JSX.Element => {
    if (!isLoggedIn) {
        return (
            <>
                <Navbar bg="dark" variant="dark" fixed="top">
                    <Navbar.Brand href="#home">LOGO</Navbar.Brand>
                    <Nav className="mr-auto"></Nav>
                    <Nav>
                        <LoginButton redirectUrl={configService.getAuth0RedirectUrl()} />
                    </Nav>
                </Navbar>
            </>
        )
    }

    return (
        <>
            <Navbar bg="dark" variant="dark" fixed="top">
                <Navbar.Brand href="#home">LOGO</Navbar.Brand>
                <Nav>
                    <LogoutButton redirectUrl={configService.getAuth0RedirectUrl()} />
                </Nav>
            </Navbar>
        </>
    )
}

export default NavigationBar
