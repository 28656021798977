import { useState } from 'react'
import { Button, Spinner } from 'react-bootstrap'
import configService from 'services/Config/configService'

export interface Props {
    accessToken: string
}

const ScanInboxButton = ({ accessToken }: Props): JSX.Element => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const backendHostname = configService.getBackendHostname()

    const sendToBackend = async () => {
        try {
            const userRefreshFeedUrl = `${backendHostname}/v1/scanInbox`
            setIsLoading(true)

            await fetch(userRefreshFeedUrl, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                },
                method: 'POST',
            }).then(function (res) {
                console.log(res)
                setIsLoading(false)
            })
        } catch (e) {
            setIsLoading(false)
            console.log('error', e.message)
        }
    }

    return (
        <Button onClick={sendToBackend}>
            {isLoading && <Spinner animation="border" variant="light" />} Scan inbox
        </Button>
    )
}

export default ScanInboxButton
