import userModel, { UserModel } from './user'

export interface StoreModel {
    user: UserModel
}

const storeModel: StoreModel = {
    user: userModel,
}

export default storeModel
