import { useAuth0 } from '@auth0/auth0-react'
import { Button } from 'react-bootstrap'

type Props = {
    redirectUrl: string
}
const LogoutButton = ({ redirectUrl }: Props): JSX.Element => {
    const { logout } = useAuth0()

    return (
        <Button variant="secondary" onClick={() => logout({ returnTo: redirectUrl })}>
            Logout
        </Button>
    )
}

export default LogoutButton
