export enum UserRole {
    ADMIN = 'admin',
    FREE = 'free',
}

export type UserAccount = {
    id: string
    auth0UserId: string
    role: UserRole
    createdAt: string
    updatedAt: string
}

export type UserProfile = {
    id: string
    name: string
    avatar: string
    role: UserRole
}

export type userAccountStatus = 'succeeded' | 'failed' | 'loading' | 'inVerification' | 'notAuthorized'
