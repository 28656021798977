import { Col, Container, Row, ListGroup } from 'react-bootstrap'
import configService from 'services/Config/configService'
import DashboardHeader from 'components/DashboardHeader'
import { useTypedStoreState } from 'store/hooks'
import NavigationBar from 'components/NavigationBar'

const Dashboard = (): JSX.Element => {
    const backendHostname = configService.getBackendHostname()
    const userProfile = useTypedStoreState((state) => state.user.userProfile)

    if (!userProfile) {
        return <></>
    }

    const renderFeeds = () => {
        return (
            <Container fluid>
                <Row>
                    <Col xs={3}>
                        <ListGroup>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/rss/${userProfile.id}`}>
                                RSS Feed
                            </ListGroup.Item>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/atom/${userProfile.id}`}>
                                Atom Feed
                            </ListGroup.Item>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/json/${userProfile.id}`}>
                                Json Feed
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        )
    }

    const renderUserDetails = () => {
        return <DashboardHeader userProfile={userProfile} />
    }

    return (
        <>
            <NavigationBar isLoggedIn={true} />
            <div data-testid="wrapper">
                {renderUserDetails()}
                {renderFeeds()}
            </div>
        </>
    )
}

export default Dashboard
