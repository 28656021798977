import { createStore } from 'easy-peasy'
import UserApi from 'services/User/UserApi'
import { UserApiInterface } from 'services/User/UserApi.interface'
import model from './model'

export interface Injections {
    userApi: UserApiInterface
}

const store = createStore(model, {
    injections: {
        userApi: UserApi,
    },
    // dev tools are enabled by default -> also in production
    devTools: process.env.NODE_ENV === 'development',
})

if (process.env.NODE_ENV === 'development') {
    if (module.hot) {
        module.hot.accept('./model', () => {
            store.reconfigure(model)
        })
    }
}

export default store
