import Dashboard from 'pages/protected/Dashboard'
import { Switch, Route } from 'react-router-dom'
import Panel from 'pages/admin/Panel'
import Landingpage from 'pages/public/Landingpage'
import VerifyEmail from 'pages/public/VerifyEmail'
import ProtectedRoute from 'components/ProtectedRoute/ProtectedRoute'
import Login from 'pages/public/Login'
import { UserRole } from 'types/user'

function App(): JSX.Element {
    return (
        <Switch>
            <Route path="/" exact={true} component={Landingpage} />
            <Route path="/verifyEmail" exact={true} component={VerifyEmail} />
            <Route path="/login" component={Login} />
            <ProtectedRoute path="/dashboard" roles={[UserRole.FREE, UserRole.ADMIN]} component={Dashboard} />
            <ProtectedRoute path="/panel" roles={[UserRole.ADMIN]} component={Panel} />
        </Switch>
    )
}

export default App
