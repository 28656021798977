import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import RestApiError from './RestApiError'

/**
 * ES6 Axios Class.
 *
 * @class RestApi
 * @extends {Axios}
 * @example
 * class UserApi extends RestApi {
 *   public constructor (config) {
 *     super(config);
 *
 *     this.login = this.login.bind(this);
 *   }
 *
 *   public login (user: User) {
 *     return this.api.post<string, User, AxiosResponse<User>>("https://www.domain/login", {name: user.name, pass: user.pass})
 *        .then((res: AxiosResponse<string>) => res.data);
 *   }
 * }
 */
export default class RestApi {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [x: string]: any
    /**
     * Creates an instance of RestApi.
     *
     * @param {import("axios").AxiosRequestConfig} [config] - axios configuration.
     * @memberof RestApi
     */
    public constructor(config?: AxiosRequestConfig) {
        this.api = axios.create({ ...config })
    }

    private handleError = (error: AxiosError): void => {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new RestApiError({
                title: error.response.data.title,
                detail: error.response.data.detail || error.message,
                instance: error.response.data.instance,
                status: error.response.status,
                invalidParams: error.response.data.invalidParams,
                originalError: error,
                request: error.request,
                response: error.response,
            })
        } else if (error.request) {
            // The request was made but no response was received
            // E.g. in case of timeouts
            throw new RestApiError({
                detail: error.message,
                originalError: error,
                request: error.request,
            })
        } else {
            // Something happened in setting up the request that triggered an Error
            throw new RestApiError({
                detail: error.message,
                originalError: error,
            })
        }
    }

    /**
     * Get Uri
     *
     * @param {import("axios").AxiosRequestConfig} [config]
     * @returns {string}
     * @memberof RestApi
     */
    public getUri = (config?: AxiosRequestConfig): string => {
        return this.api.getUri(config)
    }

    /**
     * Generic request.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP axios response payload.
     * @memberof RestApi
     *
     * @example
     * restApi.request({
     *   method: "GET|POST|DELETE|PUT|PATCH"
     *   baseUrl: "http://www.domain.com",
     *   url: "/v1/users",
     *   headers: {
     *     "Content-Type": "application/json"
     *  }
     * }).then((response: AxiosResponse<User>) => response.data)
     *
     */
    public request = <T, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> => {
        return this.api.request(config).catch(this.handleError)
    }

    /**
     * HTTP GET method, used to fetch data `statusCode`: 200.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} HTTP `axios` response payload.
     * @memberof RestApi
     */
    public get = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.get(url, config).catch(this.handleError)
    }

    /**
     * HTTP DELETE method, `statusCode`: 204 No Content.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof RestApi
     */
    public delete = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.delete(url, config).catch(this.handleError)
    }

    /**
     * HTTP HEAD method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof RestApi
     */
    public head = <T, R = AxiosResponse<T>>(url: string, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.head(url, config).catch(this.handleError)
    }

    /**
     * HTTP POST method `statusCode`: 201 Created.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof RestApi
     */
    public post = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.post(url, data, config).catch(this.handleError)
    }

    /**
     * HTTP PUT method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof RestApi
     */
    public put = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.put(url, data, config).catch(this.handleError)
    }

    /**
     * HTTP PATCH method.
     *
     * @access public
     * @template T - `TYPE`: expected object.
     * @template B - `BODY`: body request object.
     * @template R - `RESPONSE`: expected object inside a axios response format.
     * @param {string} url - endpoint you want to reach.
     * @param {B} data - payload to be send as the `request body`,
     * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
     * @returns {Promise<R>} - HTTP [axios] response payload.
     * @memberof RestApi
     */
    public patch = <T, B, R = AxiosResponse<T>>(url: string, data?: B, config?: AxiosRequestConfig): Promise<R> => {
        return this.api.patch(url, data, config).catch(this.handleError)
    }

    /**
     *
     * @template T - type.
     * @param {import("axios").AxiosResponse<T>} response - axios response.
     * @returns {T} - expected object.
     * @memberof RestApi
     */
    public success = <T>(response: AxiosResponse<T>): T => {
        return response.data
    }
}
