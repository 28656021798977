import { Col, Container, Row, ListGroup } from 'react-bootstrap'
import configService from 'services/Config/configService'
import DashboardHeader from 'components/DashboardHeader'
import ScanInboxButton from 'components/ScanInboxButton'
import RefreshFeedButton from 'components/RefreshFeedButton'
import { useTypedStoreState } from 'store/hooks'
import NavigationBar from 'components/NavigationBar'

const Panel = (): JSX.Element => {
    const backendHostname = configService.getBackendHostname()
    const userProfile = useTypedStoreState((state) => state.user.userProfile)
    const accessToken = useTypedStoreState((state) => state.user.accessToken)

    if (!userProfile || !accessToken) {
        return <></>
    }

    const renderFeeds = () => {
        return (
            <Container fluid>
                <Row>
                    <Col xs={3}>
                        <ListGroup>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/rss/${userProfile.id}`}>
                                RSS Feed
                            </ListGroup.Item>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/atom/${userProfile.id}`}>
                                Atom Feed
                            </ListGroup.Item>
                            <ListGroup.Item action href={`${backendHostname}/v1/feed/json/${userProfile.id}`}>
                                Json Feed
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                </Row>
            </Container>
        )
    }

    const renderUserDetails = () => {
        return (
            <>
                <div data-testid="wrapper">
                    <DashboardHeader userProfile={userProfile} />
                    <ScanInboxButton accessToken={accessToken} />
                    &nbsp;
                    <RefreshFeedButton accessToken={accessToken} />
                </div>
            </>
        )
    }

    return (
        <div data-testid="wrapper">
            <NavigationBar isLoggedIn={true} />
            {renderUserDetails()}
            {renderFeeds()}
        </div>
    )
}

export default Panel
