import { AxiosRequestConfig } from 'axios'
import { RestApi } from 'common'
import { UserAccount } from 'types/user'
import configService from '../Config/configService'
import { UserApiInterface } from './UserApi.interface'

class UserApi extends RestApi implements UserApiInterface {
    public getUserAccount = async (accessToken: string): Promise<UserAccount> => {
        if (!accessToken || accessToken.length === 0) {
            throw Error(`Invalid accessToken: "${accessToken}"`)
        }

        const config: AxiosRequestConfig = { headers: { Authorization: `Bearer ${accessToken}` } }
        const response = await this.post<UserAccount, null>('/account', null, config)
        return this.success(response)
    }
}

const baseConfig: AxiosRequestConfig = {
    headers: {
        accepts: 'application/json',
    },
    timeout: configService.getApiTimeout(),
    baseURL: `${configService.getBackendHostname()}/v1/user`,
}

export default new UserApi(baseConfig)
