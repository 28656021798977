import NavigationBar from 'components/NavigationBar'
import { useQuery } from 'hooks/userQuery'
import { Alert, Container, Jumbotron } from 'react-bootstrap'

const Login = (): JSX.Element => {
    const query = useQuery()

    const renderErrorMessage = () => {
        if (query.get('error') === 'login') {
            return <Alert variant="danger">Etwas ist schiefgelaufen, bitte versuche es erneut</Alert>
        }

        return <></>
    }

    return (
        <div>
            <NavigationBar isLoggedIn={false} />
            {renderErrorMessage()}
            <Jumbotron fluid>
                <Container>Bitte melde dich an, um auf dise Seite zugreifen zu können</Container>
            </Jumbotron>
        </div>
    )
}

export default Login
