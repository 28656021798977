const ERROR_NAME = 'RestApiError'

export type InvalidParams = {
    name: string
    reason: string
    value: string
}

type RestApiErrorConstructor = {
    /**
     * The catched error which may contain relevant details
     */
    originalError: Error
    /**
     * Some special details or at least the error.message
     */
    detail: string
    title?: string
    instance?: string
    invalidParams?: InvalidParams[]
    /**
     * Http status code, if response with value is given
     */
    status?: number
    /**
     * The Axios request information
     */
    request?: unknown
    /**
     * The Axios response information
     */
    response?: unknown
}

type ToString = RestApiErrorConstructor & {
    name: string
}
export default class RestApiError extends Error {
    public detail: string
    public instance: string
    public invalidParams: InvalidParams[]
    public title: string
    public originalError: Error
    public status: number
    public request: unknown
    public response: unknown

    constructor({
        originalError,
        title = 'n/a',
        detail = 'n/a',
        instance = 'n/a',
        invalidParams = [],
        status = -1,
        request = null,
        response = null,
    }: RestApiErrorConstructor) {
        super(detail)
        this.name = ERROR_NAME
        this.title = title
        this.detail = detail
        this.instance = instance
        this.invalidParams = invalidParams
        this.status = status
        this.originalError = originalError
        this.request = request
        this.response = response
    }

    toString(): ToString {
        return {
            name: this.name,
            title: this.title,
            instance: this.instance,
            invalidParams: this.invalidParams,
            detail: this.detail,
            status: this.status,
            originalError: this.originalError,
        }
    }
}

// eslint-disable-next-line
const guardRestApiError = (toBeDetermined: any): toBeDetermined is RestApiError => {
    if ((toBeDetermined as RestApiError).name && toBeDetermined.name === ERROR_NAME) {
        return true
    }

    return false
}

/**
 * Check for an RestApiError with HTTP status code 404
 *
 * @param can be anything
 * @returns true if status === 404
 */
// eslint-disable-next-line
const isNotFound = (toBeDetermined: any): boolean => {
    if (guardRestApiError(toBeDetermined)) {
        return toBeDetermined.status === 404
    }

    return false
}

/**
 * Check for an RestApiError with HTTP status code 403
 *
 * @param can be anything
 * @returns true if status === 403
 */
// eslint-disable-next-line
const isForbidden = (toBeDetermined: any): boolean => {
    if (guardRestApiError(toBeDetermined)) {
        return toBeDetermined.status === 403
    }

    return false
}

export { guardRestApiError, isNotFound, isForbidden }
