import { Container, Jumbotron } from 'react-bootstrap'

const VerifyEmail = (): JSX.Element => {
    return (
        <Jumbotron fluid>
            <Container>
                <h1>Verify email</h1>
            </Container>
        </Jumbotron>
    )
}

export default VerifyEmail
