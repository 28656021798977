import { action, thunk } from 'easy-peasy'
import { UserModel } from './index.interface'
import * as Sentry from '@sentry/react'

const userModel: UserModel = {
    accessToken: null,
    setAccessToken: action((state, payload) => {
        state.accessToken = payload
    }),

    userAccount: null,
    setUserAccount: action((state, userAccount) => {
        if (userAccount) {
            Sentry.setUser({ id: userAccount.id })
        } else {
            Sentry.configureScope((scope) => scope.setUser(null))
        }

        state.userAccount = userAccount
    }),
    getUserAccount: thunk(async (actions, _payload, { injections, getStoreState }) => {
        const { userApi } = injections
        const accessToken = getStoreState().user.accessToken

        if (!accessToken) {
            actions.setUserAccount(null)
            throw Error('Cannot get user account, if accessToken is missing')
        }

        const userAccount = await userApi.getUserAccount(accessToken)
        actions.setUserAccount(userAccount)
    }),

    userProfile: null,
    setUserProfile: action((state, userProfile) => {
        if (userProfile) {
            Sentry.setUser({ id: userProfile.id })
        } else {
            Sentry.configureScope((scope) => scope.setUser(null))
        }

        state.userProfile = userProfile
    }),
}

export default userModel
export type { UserModel }
