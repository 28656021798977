import { FallbackProps } from 'react-error-boundary'
import styles from './ErrorFallback.module.scss'

const ErrorFallback = ({ error }: FallbackProps): JSX.Element => {
    return (
        <div role="alert" className={styles.message}>
            <div>
                <h4 className={styles.headline}>Something went wrong:</h4>
                <h6 className={styles.subHeadline}>Error message:</h6>
                <pre>{error?.message}</pre>
                <h6 className={styles.subHeadline}>Component stack:</h6>
                <pre>&nbsp;&nbsp;&nbsp;&nbsp;{error?.stack?.trim()}</pre>
            </div>
        </div>
    )
}

export default ErrorFallback
